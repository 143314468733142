import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import configSlice from "./slice/configuration";
import authenticationSlice from "./slice/authentication";
import localStorage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import alertSlice from "./slice/alert";
import userSummary from "./slice/userSummary";
import profile from "./slice/profile";
import uploadProgress from "./slice/uploadProgress";
import createLgSlice from "./slice/createLg";
import addEventSlice from "./slice/addevent";
import notificationSlice from "./slice/notification";
import { mainApi } from "./slice/mainApi";
import familyTree from "./slice/familyTree";

const persistConfig = {
  key: "authentication",
  storage: localStorage,
};

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    alert: alertSlice,
    authentication: persistReducer(persistConfig, authenticationSlice),
    config: configSlice,
    notification: notificationSlice,
    createLg: createLgSlice,
    userSummary: userSummary,
    profile: profile,
    uploadProgress: uploadProgress,
    addEvent: addEventSlice,
    familyTree: familyTree,
    [mainApi.reducerPath]: mainApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(mainApi.middleware),
  devTools: true,
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch);
