import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import PersonIcon from "@mui/icons-material/Person";
import Person2Icon from "@mui/icons-material/Person2";
import FavoriteIcon from "@mui/icons-material/Favorite";
import React, { useRef, useState } from "react";
import { FamilyTreeNodePopup } from "../atoms/FamilyTreeNodePopup";
import { t } from "i18next";
import { Tooltip } from "@mui/material";
export const FamilyTreeNode = ({ e, rootAncestor, updateAnchor, apiCall }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [open, setOpen] = useState(false);
  const buttonStyles = {
    backgroundColor: "#FFC43A",
    color: "#222222",
    borderRadius: "8px",
    borderColor: "transparent",
  };
  const handleMouseLeave = (e) => {
    e.target.style.backgroundColor = "#FFC43A";
    e.target.style.color = "#222222";
  };
  const handleMouseEnter = (e) => {
    e.target.style.backgroundColor = "#F1A32F";
    e.target.style.color = "#222222";
  };
  const timer = useRef();
  const nodeSize = {
    x:
      e.nodeDatum.spouses.length > 0
        ? 100 * (e.nodeDatum.spouses.length + 1) +
          e.nodeDatum.spouses.length * 12
        : 120,
    y: 140,
  };
  const foreignObjectProps = {
    width: `${nodeSize.x + 75}px`,
    height: `${nodeSize.y + 75}px`,
    x: -50,
    y: -75,
  };
  const onSingleClick = (data) => {
    setOpen(data);
  };
  const onClickHandler = (event, data) => {
    // clearTimeout(timer.current);
    // if (event.detail === 1) {
    //   timer.current = setTimeout(onSingleClick.bind(null, data), 200);
    // } else if (event.detail === 2) {
    setTimeout(apiCall.bind(null, data?.lg_id), 0);
    e.toggleNode();
    // }
  };
  if (e.nodeDatum.name === "dummyChildForSpouseGap") {
    return <></>;
  } else {
    return (
      <>
        <foreignObject {...foreignObjectProps}>
          <Box sx={{ display: "flex" }}>
            <Tooltip title={e.nodeDatum.name}>
              <Box
                sx={{
                  height: `${nodeSize.y + 35}px`,
                  width: "100px",
                  // background:
                  //   e.nodeDatum.gender !== "Male"
                  //     ? "linear-gradient(0deg, rgba(212, 29, 228, 0.47), rgba(212, 29, 228, 0.47)), linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF"
                  //     : "linear-gradient(0deg, rgba(27, 47, 232, 0.47), rgba(27, 47, 232, 0.47)), linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF",
                  // p: "4px",
                }}
              >
                <Card
                  sx={{
                    height: `${nodeSize.y + 15}px`,
                    width: "100px",
                    background:
                      e.nodeDatum.gender !== "Male"
                        ? "linear-gradient(0deg, rgba(212, 29, 228, 0.47), rgba(212, 29, 228, 0.47)), linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF"
                        : "linear-gradient(0deg, rgba(27, 47, 232, 0.47), rgba(27, 47, 232, 0.47)), linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF",
                    p: "4px",
                  }}
                  onClick={(event) => onClickHandler(event, e.nodeDatum)}
                >
                  {/* <Box sx={{ height: "16px" }}>
                {
                  // (rootAncestor?.rel_chain.startsWith(e.nodeDatum.rel_chain) ===
                  //   false ||
                  //   rootAncestor?.rel_chain === e.nodeDatum.rel_chain) &&
                  //   e.nodeDatum.rel_chain.startsWith("I") &&
                  //   (e.nodeDatum.father_lg_id !== null ||
                  //     e.nodeDatum.mother_lg_id !== null) &&
                  e.nodeDatum.rel_chain !== "I" && (
                    <button
                      style={{
                        padding: 0,
                        // background: "transparent",
                        borderRadius: "50%",
                        border: "none",
                      }}
                      onClick={
                        () =>
                          // e.nodeDatum.rel_chain === ""
                          // ?
                          apiCall(e.nodeDatum.lg_id)
                        // : updateAnchor(e.nodeDatum.rel_chain)
                      }
                    >
                      <NatureOutlinedIcon sx={{ fontSize: "16px" }} />
                    </button>
                  )
                }
              </Box> */}
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: "8px",
                      backgroundColor:
                        e.nodeDatum.rel_chain === "I" ? "#FFFA80" : "#ffffff",
                      // e.nodeDatum.rel_chain === "I" &&
                      // e.nodeDatum.gender !== "Male"
                      //   ? "#FFF0F5"
                      //   : e.nodeDatum.rel_chain === "I" &&
                      //     e.nodeDatum.gender !== "Female"
                      //   ? "#F0F8FF"
                      //   : "#FFFFFF",
                      boxShadow: " 0px 2px 4px 2px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        border: `${
                          e.nodeDatum.rel_chain === "I" ? "3px" : "thin"
                        } ${
                          e.nodeDatum.deceased === "No" ? "solid" : "dashed"
                        } ${
                          e.nodeDatum.gender !== "Male" ? "#CB4AAF" : "#039BE5"
                        }`,
                        p: "1px",
                      }}
                    >
                      {e.nodeDatum.readURL === "NOFILE" ? (
                        <Box
                          sx={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            p: 1,
                          }}
                          // onClick={e.toggleNode}
                        >
                          {e.nodeDatum.gender === "Male" ? (
                            <PersonIcon sx={{ fontSize: "80px" }} />
                          ) : (
                            <Person2Icon sx={{ fontSize: "80px" }} />
                          )}
                        </Box>
                      ) : (
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "50%",
                          }}
                          src={e.nodeDatum.readURL}
                          // onClick={e.toggleNode}
                        />
                      )}
                    </Box>
                    <Typography
                      sx={{
                        height: "49px",
                        display: "-webkit-box",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "capitalize",
                        "-webkit-box-orient": "vertical",
                        "-webkit-line-clamp": "2",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      align="center"
                      // onClick={(event) => {
                      //   onClickHandler(event, e.nodeDatum);
                      // }}
                      component={"div"}
                    >
                      {e.nodeDatum.name}
                    </Typography>
                  </Box>
                </Card>
                <Box
                  sx={{
                    mt: "-12px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{
                      ...buttonStyles,
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => onSingleClick(e.nodeDatum)}
                  >
                    {t("display.text.button.visit")}
                  </button>
                </Box>
              </Box>
            </Tooltip>

            {e.nodeDatum.spouses.map((item, idx) => (
              <Box
                key={idx}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // position: "relative", this will not work properly in ios
                }}
              >
                <Box
                  sx={{
                    marginBottom: "20px",
                    width: "50px",
                  }}
                >
                  <Divider
                    sx={{
                      "&::before, &::after": {
                        borderColor: "black.main",
                      },
                    }}
                  >
                    <FavoriteIcon fontSize="small" color="error" />
                  </Divider>
                </Box>
                <Tooltip title={item.name}>
                  <Box
                    sx={{
                      height: `${nodeSize.y + 35}px`,
                      width: "100px",
                      // background:
                      //   item.gender !== "Male"
                      //     ? "linear-gradient(0deg, rgba(212, 29, 228, 0.47), rgba(212, 29, 228, 0.47)), linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF"
                      //     : "linear-gradient(0deg, rgba(27, 47, 232, 0.47), rgba(27, 47, 232, 0.47)), linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF",
                      // p: "4px",
                    }}
                  >
                    {/* <Box sx={{ height: "16px" }}>
                    {
                      // (item.rel_chain === "" ||
                      //   ((rootAncestor?.rel_chain.startsWith(item.rel_chain) ===
                      //     false ||
                      //     rootAncestor?.rel_chain === item.rel_chain) &&
                      //     item.rel_chain.startsWith("I") &&
                      //     (item.father_lg_id !== null ||
                      //       item.mother_lg_id !== null))) &&
                      <button
                        style={{
                          padding: 2,
                          // background: "transparent",
                          borderRadius: "50%",
                          border: "none",
                        }}
                        onClick={
                          () =>
                            // item.rel_chain === ""
                            //   ?
                            apiCall(item.lg_id)
                          // : updateAnchor(item.rel_chain)
                        }
                      >
                        <NatureOutlinedIcon sx={{ fontSize: "16px" }} />
                      </button>
                    }
                  </Box> */}
                    <Card
                      sx={{
                        height: `${nodeSize.y + 15}px`,
                        width: "100px",
                        background:
                          item.gender !== "Male"
                            ? "linear-gradient(0deg, rgba(212, 29, 228, 0.47), rgba(212, 29, 228, 0.47)), linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF"
                            : "linear-gradient(0deg, rgba(27, 47, 232, 0.47), rgba(27, 47, 232, 0.47)), linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF",
                        p: "4px",
                      }}
                      onClick={(event) => onClickHandler(event, item)}
                    >
                      <Box
                        sx={{
                          p: 1,
                          borderRadius: "8px",
                          backgroundColor:
                            item.rel_chain === "I" ? "#FFFA80" : "#ffffff",

                          // item.rel_chain === "I" && item.gender !== "Male"
                          //   ? "#FFF0F5"
                          //   : item.rel_chain === "I" && item.gender !== "Female"
                          //   ? "#F0F8FF"
                          //   : "#FFFFFF",
                          boxShadow: " 0px 2px 4px 2px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "80px",
                            height: "80px",
                            borderRadius: "50%",
                            border: `${
                              item.rel_chain === "I" ? "3px" : "thin"
                            } ${item.deceased === "No" ? "solid" : "dashed"} ${
                              item.gender !== "Male" ? "#CB4AAF" : "#039BE5"
                            }`,
                            p: "1px",
                          }}
                        >
                          {item.readURL === "NOFILE" ? (
                            <Box
                              sx={{
                                height: "100%",
                                width: "100%",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                p: 1,
                              }}
                              // onClick={e.toggleNode}
                            >
                              {item.gender === "Male" ? (
                                <PersonIcon sx={{ fontSize: "80px" }} />
                              ) : (
                                <Person2Icon sx={{ fontSize: "80px" }} />
                              )}
                            </Box>
                          ) : (
                            <img
                              style={{
                                height: "100%",
                                width: "100%",
                                borderRadius: "50%",
                              }}
                              src={item.readURL}
                              // onClick={e.toggleNode}
                            />
                          )}
                        </Box>
                        <Typography
                          sx={{
                            height: "49px",
                            display: "-webkit-box",
                            alignItems: "center",
                            justifyContent: "center",
                            textTransform: "capitalize",
                            "-webkit-box-orient": "vertical",
                            "-webkit-line-clamp": "2",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          align="center"
                          // onClick={(event) => {
                          //   onClickHandler(event, item);
                          // }}
                          component={"div"}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                    </Card>
                    <Box
                      sx={{
                        mt: "-12px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        style={{
                          ...buttonStyles,
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => onSingleClick(item)}
                      >
                        {t("display.text.button.visit")}
                      </button>
                    </Box>
                  </Box>
                </Tooltip>
              </Box>
            ))}
          </Box>
        </foreignObject>
        <FamilyTreeNodePopup
          open={Boolean(open)}
          close={() => setOpen(false)}
          {...open}
        />
      </>
    );
  }
};
