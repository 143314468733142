import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { t } from "i18next";
import { entryDeleteButtonLogic } from "../../utils/functions";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 150,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const MenuButton = ({
  entry_created_by,
  onDelete = () => {},
  onEdit = () => {},
  onInfo = () => {},
  lg_id = "",
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const showDeleteButton = entryDeleteButtonLogic(lg_id);

  //-----------------------Declare function Here-----------------------------//

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    onDelete(true);
    setAnchorEl(null);
  };
  const handleEdit = () => {
    onEdit(true);
    setAnchorEl(null);
  };
  const handleInfo = () => {
    onInfo(true);
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        sx={{ p: 0 }}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ ".MuiMenuItem-root": { px: 1 } }}
      >
        {entry_created_by === "Me" && (
          <MenuItem onClick={handleEdit} disableRipple>
            <EditIcon />
            {t("display.text.button.edit")}
          </MenuItem>
        )}
        <MenuItem onClick={handleInfo} disableRipple>
          <InfoOutlinedIcon />
          {t("display.text.button.info")}
        </MenuItem>
        {showDeleteButton && (
          <MenuItem onClick={handleClose} disableRipple>
            <Button
              sx={{ width: "100%" }}
              onClick={handleDelete}
              variant="contained"
              color="error"
            >
              {t("display.text.button.delete")}
            </Button>{" "}
          </MenuItem>
        )}
      </StyledMenu>
    </div>
  );
};
