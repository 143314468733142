import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  findOwnerAndSelfUser,
  findSelf,
  formatRelationship,
  postApi,
} from "../../utils/functions";
import { subscribeApi } from "../../api/subscribe";
import { t } from "i18next";
import LinearProgress from "@mui/material/LinearProgress";
import { useGetCountries, useWindowDimensions } from "../../utils/hooks";
import MenuItem from "@mui/material/MenuItem";
import CustomAvatar from "../../UI/CustomAvathar";
import {
  useLazyChkCouponQuery,
  useLazyGetActiveSubsQuery,
} from "../../redux/slice/payment";
import { assetSource } from "../../utils/ListItems";
import { getUserSummaryApi } from "../../api/getUserSummaryApi";
import CircularProgress from "@mui/material/CircularProgress";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";

export const PlanUpgrade = () => {
  //-----------------------Declare Hooks Here-----------------------------//
  const self = findSelf();
  const containerRef = useRef(null);
  const countries = useGetCountries();
  const { width } = useWindowDimensions(containerRef);
  const [getActiveSubs, { data, isLoading, isFetching }] =
    useLazyGetActiveSubsQuery();
  const bottlePrice = data?.output?.subs_amount || 0;
  const [
    checkCoupon,
    {
      data: couponData,
      isLoading: couponIsLoading,
      isFetching: couponIsFetching,
    },
  ] = useLazyChkCouponQuery();
  const { userPlan } = useSelector((state) => state.userSummary);
  const inkLeft =
    100 -
    Math.round(
      (Number(userPlan?.used_flinks) / Number(userPlan?.total_flinks)) * 100
    );
  const [bottles, setBottles] = useState(1);
  const [relatedTo, setRelatedTo] = useState(self);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState();
  const [loading, setLoading] = useState(false);
  const [redirectToPayment, setRedirectToPayment] = useState(false);
  const [ownerAndSelfUser, setOwnerAndSelfUser] = useState(
    findOwnerAndSelfUser()
  );
  useEffect(() => {
    getActiveSubs(self?.lg_id).then((response) => {
      if (!response.isError) {
        setBottles(response?.data?.output.subs_qty);
      }
    });
  }, []);

  const handleSubscribeTo = (data) => {
    setRelatedTo(data);
    getActiveSubs(data?.lg_id).then((response) => {
      if (!response.isError) {
        setBottles(response?.data?.output.subs_qty);
      }
    });
  };
  const handleApplyCoupon = () => {
    checkCoupon(coupon)
      .then((response) => {
        if (response.isError) {
          setCoupon("");
          setDiscount();
        } else {
          setDiscount(response.data.output);
        }
      })
      .catch((e) => {
        setCoupon("");
        setDiscount();
        console.error(e);
      });
  };

  //-----------------------Declare Functions Here-----------------------------//

  const increaseBottleCount = () => {
    if (bottles < 1000) setBottles((pre) => pre + 1);
  };
  const decreaseBottleCount = () => {
    if (bottles > 0) setBottles((pre) => pre - 1);
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const onSubscribe = (paymentMethod) => {
    // if (window.ReactNativeWebView) {
    //   // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
    //   window.ReactNativeWebView.postMessage("Wayne is coming again");
    // } else {
    const self = findSelf();
    let formData = {
      lg_id: relatedTo.lg_id,
      subs_product: "reg_flink_bottle",
      subs_interval: "month",
      subs_qty: bottles,
      user_cntry: countries?.country_code_iso3,
      coupon_code: coupon ? coupon : "",
      pay_thru: paymentMethod,
    };
    setRedirectToPayment(true);
    subscribeApi(formData)
      .then(async (response) => {
        if (!response.error) {
          const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
          );
          if (res) {
            const options = {
              key: "rzp_test_84bYt3xWzT6uYi",
              subscription_id: response.subs_id,
              name: "Famscape",
              description: "Monthly Test Plan",
              image: `${assetSource}/logo_logoSmall`,
              handler: (response) => {
                const formData = {
                  lg_id: relatedTo.lg_id,
                  payment_id: response.razorpay_payment_id,
                  subs_id: response.razorpay_subscription_id,
                  rz_sign: response.razorpay_signature,
                };
                postApi("verifyRzPay", formData).then((response) => {
                  if (!response.error) {
                    getUserSummaryApi();
                    setRedirectToPayment(false);
                  }
                });
              },
              modal: {
                ondismiss: () => {
                  setRedirectToPayment(false);
                  // Logic to handle when the user cancels the payment
                },
              },
              // prefill: {
              //   // name: `${self.fname} ${self.lname}`,
              //   // email: "gaurav.kumar@example.com",
              //   // contact: "+918667835993",
              // },
              theme: {
                color: "#4a154b",
              },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          } else {
            setRedirectToPayment(false);
          }
        }
      })
      .catch((e) => {
        setRedirectToPayment(false);
        console.error(e);
      });
  };

  return (
    <IntlProvider locale="en" defaultLocale="en">
      <Card
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            position: "relative",
          }}
        >
          <img
            style={{ width: "100%" }}
            src={`${assetSource}/img_paymentBg.svg`}
          />
          <Box
            sx={{
              width: "32%",
              position: "absolute",
              bottom: "0px",
              right: "0px",
            }}
          >
            <img
              style={{ width: "100%" }}
              src={`${assetSource}/img_inkBottle`}
            />
          </Box>
          <Box
            ref={containerRef}
            sx={{
              height: "100%",
              width: "50%",
              position: "absolute",
              top: "4px",
              left: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: `${width / 23 < 16 ? width / 23 : 16}px`,
                fontWeight: 600,
                color: "#333333",
                px: 1,
                width: "max-content",
              }}
              align="center"
            >
              {t("display.text.text.youHave", {
                param1: relatedTo.fname,
              })}
            </Typography>
            <Typography
              sx={{
                fontSize: `${width / 11 < 80 ? width / 11 : 80}px`,
                fontWeight: 200,
                color: "#FFFFFF",
                lineHeight: 1,
              }}
            >
              {data?.output?.subs_qty}
            </Typography>
            <Typography
              sx={{
                fontSize: `${width / 23 < 16 ? width / 23 : 16}px`,
                fontWeight: 600,
                color: "#333333",
                letterSpacing: `${width / 80 < 8 ? width / 80 : 8}px`,
              }}
            >
              {t("display.text.text.inkBottle")}
            </Typography>
            <Box
              sx={{
                width: "70%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 0.5,
              }}
            >
              <LinearProgress
                variant="determinate"
                value={inkLeft}
                sx={{
                  width: "70%",
                  color: "#4148A9",
                  borderRadius: 2,
                  mr: 1,
                }}
                color="inherit"
              />{" "}
              <img
                src={`${assetSource}/img_inkDrop.svg`}
                style={{ width: "14px", height: "14px" }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "transparent",
                borderRadius: 1,
              }}
            >
              <Typography
                sx={{
                  color: "#4148A9",
                  fontSize: `${width / 32 < 12 ? width / 32 : 12}px`,
                }}
                variant="caption"
              >
                {t("display.text.text.flinksLeft", {
                  param1:
                    Number(userPlan.total_flinks) -
                    Number(userPlan.used_flinks),
                  param2: userPlan.total_flinks,
                })}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack sx={{ width: "100%" }} spacing={1.5} alignItems={"center"}>
            <Typography
              sx={{
                p: 1,
                fontSize: "16px",
                fontWeight: 600,
                color: "#333333",
              }}
            >
              {t("display.text.text.subscribeToRelatives")}
            </Typography>
            <TextField
              select
              // label={t("display.text.inputField.relatedTo")}
              value={relatedTo}
              required
              onChange={(e) => handleSubscribeTo(e.target.value)}
              onKeyDown={(e) =>
                e.key === "Enter" ? handleSubscribeTo(e.target.value) : null
              }
              variant="outlined"
              sx={{
                width: { xs: "50%", sm: "45%" },
                height: "30px",
                "&.MuiTextField-root .MuiInputBase-root": { height: "30px" },
                "&.MuiTextField-root .MuiInputLabel-root": { top: "10px" },
                "&.MuiTextField-root .MuiFormLabel-filled": {
                  top: "0px !important",
                },
                "&.MuiTextField-root .Mui-focused": { top: "0px" },
              }}
            >
              {ownerAndSelfUser.map((option, index) => (
                <MenuItem
                  sx={{ maxheight: "240px", maxWidth: "240px" }}
                  key={index}
                  value={option}
                >
                  <Box
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textWrap: "nowrap",
                    }}
                  >
                    {/* <CustomAvatar sx={{ mr: 1 }} src={option.readURL} /> */}
                    <Typography
                      noWrap
                      sx={{
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textWrap: "nowrap",
                      }}
                    >
                      {option.fname + " " + option.lname}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </TextField>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                color: "#333333",
                mb: { xs: "40px !important", sm: "40px !important", md: 0 },
              }}
            >
              {t("display.text.text.subscribeFor")}
            </Typography>
            <Grid container spacing={0} sx={{}}>
              <Grid item sx={{ p: 2 }} xs={12} sm={12} md={6} lg={6}>
                <Box
                  sx={{
                    width: "100%",
                    p: 2,
                    borderStyle: "solid",
                    borderColor: "border.main",
                    borderWidth: "thin",
                    height: "80px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ mt: "-55px" }}>
                    <CustomAvatar
                      src={relatedTo.readURL}
                      sx={{ height: "75px", width: "75px" }}
                    />
                  </Box>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: 400, color: "#333333" }}
                  >{`${relatedTo.fname} ${relatedTo.lname}`}</Typography>
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: 400, color: "#666666" }}
                  >
                    {formatRelationship(relatedTo.lg_id)}
                  </Typography>
                </Box>
              </Grid>
              <Grid sx={{ p: 2 }} item xs={12} sm={12} md={6} lg={6}>
                <Box
                  sx={{
                    width: "100%",
                    p: 2,
                    borderStyle: "solid",
                    borderColor: "border.main",
                    borderWidth: "thin",
                    height: "80px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: 400, color: "#333333" }}
                  >
                    {t("display.text.text.addInkBottles")}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        minWidth: 0,
                        width: "fit-content",
                        height: "24px",
                        p: 0,
                        // borderRadius: 0,
                        borderTopRightRadius: 1,
                        borderBottomRightRadius: 1,
                      }}
                      onClick={decreaseBottleCount}
                      disabled={bottles <= data?.output?.subs_qty}
                    >
                      <RemoveIcon />
                    </Button>
                    <TextField
                      sx={{
                        width: "85px",
                        p: 0,
                        "& input": {
                          p: 0,
                          height: "24px",
                          textAlign: "center",
                        },
                        "& .MuiOutlinedInput-root": { borderRadius: 0 },
                      }}
                      value={bottles}
                      disabled
                    />
                    <Button
                      variant="contained"
                      sx={{
                        minWidth: 0,
                        width: "fit-content",
                        height: "24px",
                        p: 0,
                        // borderRadius: 0,
                        borderTopLeftRadius: 1,
                        borderBottomLeftRadius: 1,
                      }}
                      onClick={increaseBottleCount}
                    >
                      <AddIcon />
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Card
              sx={{
                borderStyle: "solid",
                borderColor: "border.main",
                borderWidth: "thin",
              }}
            >
              <Stack
                direction={"row"}
                sx={{
                  display: "flex",
                  p: "1px",
                  backgroundColor: "transparent",
                  borderRadius: 1,
                }}
                spacing={1}
                id="search-category-select"
              >
                <TextField
                  placeholder={t("display.text.text.couponPlaceholder")}
                  sx={{
                    width: "100%",
                    "& ::before": { display: "none" },
                    "& ::after": { display: "none" },
                    "& div": {
                      borderRadius: 1,
                      backgroundColor: "#fff",
                    },
                    "& div > input": { px: 1, py: 1.5 },
                  }}
                  variant="filled"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                />
                <Button onClick={handleApplyCoupon}>
                  {couponIsLoading ? (
                    <CircularProgress size="30px" />
                  ) : (
                    t("display.text.button.apply")
                  )}
                </Button>
              </Stack>
            </Card>
            <Box sx={{ flex: "display", justifyContent: "center" }}>
              <Stack spacing={1.5}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 600, color: "#333333" }}
                >
                  {t("display.text.text.MonthlySubscriptionSummary")}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: 400, color: "#666666" }}
                  >
                    {t("display.text.text.familyInkBottle")}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: 400, color: "#666666" }}
                  >
                    {`${bottles} x `}{" "}
                    <FormattedNumber
                      value={bottlePrice}
                      style="currency"
                      currency={data?.output?.currency}
                    />
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: 400, color: "#666666" }}
                  >
                    {t("display.text.text.couponDiscount", {
                      param1: discount?.discountAmount || "0",
                    })}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: 400, color: "#666666" }}
                  >
                    <FormattedNumber
                      value={
                        Number(bottles) *
                          Number(bottlePrice) *
                          (Number(discount?.discountAmount) / 100) || 0
                      }
                      style="currency"
                      currency={data?.output?.currency}
                    />
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: 600, color: "#333333" }}
                  >
                    {`Total`}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: 600, color: "#333333" }}
                  >
                    <FormattedNumber
                      value={
                        Number(bottles) * Number(bottlePrice) -
                          Number(bottles) *
                            Number(bottlePrice) *
                            (Number(discount?.discountAmount) / 100) ||
                        Number(bottles) * Number(bottlePrice)
                      }
                      style="currency"
                      currency={data?.output?.currency}
                    />
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Stack
              spacing={2}
              direction={"row"}
              sx={{ display: "flex", justifyContent: "center", pb: 1 }}
            >
              {countries?.country_code_iso3 === "IND" && (
                <LoadingButton
                  loading={loading || redirectToPayment}
                  variant="contained"
                  sx={{ fontSize: "14px", fontWeight: 600, color: "#222222" }}
                  onClick={() => onSubscribe("upi")}
                  disabled={data?.output.subs_qty === bottles}
                >
                  {t("display.text.button.upi")}
                </LoadingButton>
              )}
              <LoadingButton
                loading={loading || redirectToPayment}
                variant="contained"
                sx={{ fontSize: "14px", fontWeight: 600, color: "#222222" }}
                onClick={() => onSubscribe("card")}
                disabled={data?.output.subs_qty === bottles}
              >
                {" "}
                {t("display.text.button.card")}
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
        <Dialog open={redirectToPayment}>
          <Card
            sx={{
              // height: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Stack spacing={1.5} sx={{ pb: 2 }}>
              <Typography
                sx={{ fontSize: "18px", fontWeight: 600, color: "#333333" }}
                align="center"
              >
                {t("display.text.text.redirectingToPaymentGateway")}
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 400, color: "#666666" }}
                align="center"
              >
                {t("display.text.text.pleaseDoNotPressTheBackOrRefreshButton")}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  height: "18px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            </Stack>
          </Card>
        </Dialog>
      </Card>
    </IntlProvider>
  );
};
