import Card from "@mui/material/Card";
import React, { useState } from "react";
import { ChooseRelationship } from "./ChooseRelationship";
import CustomAvatar from "../../UI/CustomAvathar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { findWho } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { addRelationApi } from "../../api/addRelationApi";
import { rejectRelationApi } from "../../api/rejectRelationApi";
import { getUserSummaryApi } from "../../api/getUserSummaryApi";

export const PossibleRelatioCard = ({
  userId = "",
  potential_relation = "",
  name = "",
  link = "",
  relation_of_lg = "",
  gender = "",
  file_type = "",
  fstr = "",
  objKey = "",
  readURL = "",
  successCallBack = () => {},
  rejectCallBack = () => {},
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [chooseRelationPromt, setChooseRelationPromt] = useState(false);
  const [relatedTo, setRelatedTo] = useState(null);
  const [relationship, setRelationship] = useState(null);
  const navigate = useNavigate();

  //-----------------------Declare Functions Here-----------------------------//

  const handleClose = (value) => {
    if (value === "next") {
      handleNext();
    } else {
      setChooseRelationPromt(false);
      setRelatedTo(null);
      setRelationship(null);
    }
  };

  const handleNext = () => {
    let formData = {
      lg_id: relatedTo?.lg_id,
      base_lg_id: userId,
      base_relation: relationship,
    };
    addRelationApi(formData).then(() => {
      getUserSummaryApi();
      successCallBack(relatedTo?.lg_id, userId, "referral");
      handleClose();
    });
  };

  const acceptRelation = () => {
    if (potential_relation === "referral") setChooseRelationPromt(true);
    else {
      let formData = {
        lg_id: relation_of_lg,
        base_lg_id: userId,
        base_relation:
          potential_relation === "Child"
            ? gender === "Male"
              ? "Son"
              : "Daughter"
            : gender === "Male"
            ? "Father"
            : "Mother",
      };
      addRelationApi(formData).then(() => {
        getUserSummaryApi();
        successCallBack(relation_of_lg, userId);
        handleClose();
      });
    }
  };
  const rejectRelation = () => {
    let formData;
    if (potential_relation === "referral") {
      formData = {
        lg_id: relation_of_lg,
        potential_relation_lg: userId,
        potential_relation: potential_relation,
      };
    } else {
      formData = {
        lg_id: relation_of_lg,
        potential_relation_lg: userId,
        potential_relation:
          potential_relation === "Child"
            ? gender === "Male"
              ? "Son"
              : "Daughter"
            : gender === "Male"
            ? "Father"
            : "Mother",
      };
    }
    rejectRelationApi(formData).then(() => {
      rejectCallBack(relation_of_lg, userId);
      handleClose();
    });
  };

  return (
    <Card
      sx={{
        width: "175px",
        borderStyle: "solid",
        borderColor: "border.main",
        borderWidth: "thin",
        overflow: "visible",
      }}
    >
      <Stack spacing={1}>
        <Button
          sx={{ color: "inherit" }}
          onClick={() => navigate(`/profile/${link.split("/")[4]}/about`)}
        >
          <Box sx={{ p: 1 }}>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <CustomAvatar
                src={readURL}
                sx={{ height: "90px", width: "90px" }}
              />
            </Box>
            <Typography
              sx={{
                fontWeight: "bold",
                height: "54px",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
              variant="body1"
            >
              {name}
            </Typography>
            <Typography
              align="center"
              sx={{
                height: "48px",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                textTransform: "none",
              }}
            >
              {potential_relation === "referral"
                ? t("display.text.text.referral")
                : t("display.text.text.isYourRelativeHomeFeed", {
                    param1: findWho(relation_of_lg)?.name,
                    param2: t(
                      `display.text.text.${
                        potential_relation === "Child"
                          ? gender === "Male"
                            ? "son"
                            : "daughter"
                          : gender === "Male"
                          ? "father"
                          : "mother"
                      }`
                    ),
                  })}
            </Typography>
          </Box>
        </Button>
        <Box sx={{ display: "flex" }}>
          <Button
            sx={{
              width: "50%",
              borderRadius: 0,
              borderBottomLeftRadius: "8px",
            }}
            variant="contained"
            color="secondary"
            onClick={rejectRelation}
          >
            {t("display.text.button.no")}
          </Button>
          <Button
            sx={{
              width: "50%",
              borderRadius: 0,
              borderBottomRightRadius: "8px",
            }}
            variant="contained"
            color="primary"
            onClick={acceptRelation}
          >
            {t("display.text.button.yes")}
          </Button>
        </Box>
      </Stack>
      <ChooseRelationship
        open={chooseRelationPromt}
        relatedTo={relatedTo}
        relationship={relationship}
        setRelatedTo={setRelatedTo}
        setRelationship={setRelationship}
        close={(e) => handleClose(e)}
        name={name}
        mode={"add"}
      />
    </Card>
  );
};
