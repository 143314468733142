import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React from "react";

export const GrannyTip = ({ message, button = false, sx = {} }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  return (
    <>
      <Card
        sx={{
          backgroundColor: "background.main",
          // display: "flex",
          borderLeft: "7px",
          borderLeftStyle: "solid",
          borderLeftColor: "info.main",
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          p: 1,
        }}
      >
        <Typography
          variant="body1"
          sx={{ color: "grannyTip.main", flex: 1 }}
          component={"div"}
        >
          {message}
        </Typography>
      </Card>
    </>
  );
};
