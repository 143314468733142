import axios from "axios";
import { store } from "../redux/store";
import {
  resetUploadProgress,
  setUploadProgress,
} from "../redux/slice/uploadProgress";
import imageCompression from "browser-image-compression";
import { setAlert } from "../redux/slice/alert";
import { somethingWentWrong } from "../utils/constants";

export const uploadFileApi = async (postDetailArr, file, size) => {
  return await Promise.all(
    postDetailArr.map(async (item) => {
      let url = item.formAttributes.action;
      let objKey = item.objKey.split("/");
      let options = {
        maxSizeMB:
          objKey[1] === "thmb" ? 0.095 : objKey[1] === "mnThmb" ? 0.01 : size,
        maxWidthOrHeight:
          objKey[1] === "thmb" ? 720 : objKey[1] === "mnThmb" ? 180 : 720,
        useWebWorker: true,
      };
      let compressedFile =
        file.type.split("/")[0] === "image"
          ? await imageCompression(file, options)
          : file;
      const formData = new FormData();
      Object.keys(item.formInputs).forEach(async (key) => {
        formData.append(key, item.formInputs[key]);
      });
      formData.append("content-Type", file.type);
      formData.append("file", compressedFile);
      try {
        await axios.post(url, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          method: item.formAttributes.method,
          enctype: item.formAttributes.enctype,
          onUploadProgress: (progressEvent) => {
            store.dispatch(
              setUploadProgress(
                parseInt((progressEvent.loaded / progressEvent.total) * 100)
              )
            );
          },
        });
        return true;
      } catch (e) {
        store.dispatch(resetUploadProgress());
        store.dispatch(setAlert(somethingWentWrong));
        return false;
      }
    })
  );
};
