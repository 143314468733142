import React from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
import { CustomDialog } from "../../UI/CustomDialog";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { SettingsPage } from "../molecules/SettingsPage";
import { AccountSettings } from "../molecules/AccountSettings";
import { LifografSettings } from "../molecules/LifografSettings";
import { LifografSettingsPage } from "../molecules/LifografSettingsPage";
import { ChangePasswordPage } from "../forms/ChangePasswordPage";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";

export const Settings = () => {
  const { userStatus } = useSelector((state) => state.config);
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const handleNavigation = (_, iconType) => {
    if (iconType === "icon2") {
      navigate("/");
    } else {
      if (params["*"].split("/").length === 2) navigate("/settings/lifograf");
      if (params["*"] === "lifograf") navigate("/settings");
      if (params["*"] === "") navigate("/");
      if (params["*"] === "account") navigate("/settings");
      if (params["*"] === "account/password") navigate("/settings/account");
    }
  };
  const formatTitle = () => {
    if (
      params["*"].split("/").length === 2 &&
      params["*"].split("/")[0] === "lifograf"
    )
      return t("display.text.text.lifografSettings");
    if (params["*"] === "lifograf")
      return t("display.text.text.lifografSettings");
    if (params["*"] === "") return t("display.text.button.settings");
    if (params["*"] === "account")
      return t("display.text.text.accountSettings");
    if (params["*"] === "account/password")
      return t("display.text.text.accountSettings");
  };
  const formatSecondaryTitle = () => {
    if (params["*"] === "account/password")
      return t("display.text.text.passwordSettings");
    if (
      params["*"].split("/").length === 2 &&
      params["*"].split("/")[0] === "lifograf"
    )
      return t("display.text.text.manageLifograf");
  };

  return (
    <CustomDialog
      open={true}
      title={
        <Typography
          variant="h6"
          sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
          align="center"
        >
          {formatTitle()}
        </Typography>
      }
      secondaryTitle={
        <Typography
          variant="caption"
          align="center"
          component={"div"}
          sx={{ fontSize: "14px" }}
        >
          {formatSecondaryTitle()}
        </Typography>
      }
      icon={location.pathname === "/settings" ? <null /> : <ArrowBackIcon />}
      icon2={location.pathname === "/settings" ? <CloseIcon /> : <CloseIcon />}
      iconPlacement="both"
      close={handleNavigation}
    >
      <Card sx={{ minHeight: "250px" }}>
        <Routes>
          <Route exact path="/" element={<SettingsPage />} />
          <Route exact path="/account" element={<AccountSettings />} />
          <Route
            exact
            path="/account/password"
            element={<ChangePasswordPage />}
          />
          <Route
            exact
            path="/lifograf"
            element={
              userStatus === "active" ? (
                <LifografSettings />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/lifograf/:lg_id"
            element={
              userStatus === "active" ? (
                <LifografSettingsPage />
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Routes>
      </Card>
    </CustomDialog>
  );
};
