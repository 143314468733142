import { setAlert } from "../redux/slice/alert";
import { updateNotificationPromt } from "../redux/slice/authentication";
import {
  updateInviteToken,
  updateAppReady,
  updateUserStatus,
} from "../redux/slice/configuration";
import { updateCurrentPage, updateMode } from "../redux/slice/createLg";
import { updateActiveNodeLgId } from "../redux/slice/familyTree";
import {
  updateNudgePost,
  updateRelReqNotification,
} from "../redux/slice/notification";
import {
  updateBlockedLg,
  updateOwnedLg,
  updateTrending,
  updateUserPlan,
} from "../redux/slice/userSummary";
import { store } from "../redux/store";
import { findSelf, getApi } from "../utils/functions";
import { getRelationsApi } from "./getRelationsApi";

export const getUserSummaryApi = () => {
  return getApi("getUsrSummary").then(async (response) => {
    if (!response.error) {
      if (response.ownedLG.length > 1) {
        store.dispatch(updateNotificationPromt({ eligible: true }));
      }
      store.dispatch(updateInviteToken(response.inviteString));
      store.dispatch(
        updateUserStatus(
          response.user_status === "passive" ? "inactive" : "active"
        )
      );
      store.dispatch(updateBlockedLg(response.blocking_lg_list));
      store.dispatch(updateOwnedLg(response.ownedLG));
      store.dispatch(updateRelReqNotification(response?.pending_rel_req));
      store.dispatch(updateUserPlan(response.userAcct));
      store.dispatch(updateTrending(response.trending));
      let self = findSelf();
      store.dispatch(
        updateActiveNodeLgId({
          lg_id: self?.lg_id,
          name: `${self?.fname} ${self?.lname}`,
        })
      );
      store.dispatch(
        updateNudgePost({
          nudgeAddRelations: self?.nudgeAddRelations,
          nudgeNewPost: self?.nudgeNewPost,
          nudgeProfileDetails: self?.nudgeProfileDetails,
        })
      );
      if (response.ownedLG.length === 0) {
        store.dispatch(updateMode("self"));
        store.dispatch(updateCurrentPage("welcome"));
        store.dispatch(updateAppReady("self"));
      } else {
        let self = findSelf();
        if (self) {
          await getRelationsApi(self?.lg_id);
          return;
        }
        store.dispatch(updateAppReady(true));
      }
    } else {
      store.dispatch(setAlert(response));
    }
  });
};
