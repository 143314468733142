import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import { useStyles } from "../utils/Theme";
import { useSelector } from "react-redux";
import DialogActions from "@mui/material/DialogActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CustomDialog = ({
  children,
  title = "",
  secondaryTitle = "",
  open,
  close = () => {},
  iconPlacement = "right",
  icon = <CloseIcon />,
  icon2 = <CloseIcon />,
  onClose = true,
  action,
}) => {
  //-----------------------Declare Hooks Here-----------------------------//
  const classes = useStyles();
  const { device } = useSelector((state) => state.config);
  useEffect(() => {
    if (open) {
      window.history.pushState(null, "", window.location.href);
      window.addEventListener("popstate", () => {
        close(false);
      });
    }
    // eslint-disable-next-line
  }, [open]);
  //-----------------------Declare Functions Here-----------------------------//

  const Headers = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          position: "relative",
        }}
      >
        {(iconPlacement === "left" || iconPlacement === "both") && (
          <IconButton
            color="inherit"
            onClick={() => {
              close(false, "icon1");
            }}
            sx={{ position: "absolute" }}
          >
            {icon}
          </IconButton>
        )}
        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>{title}</Box>
          <Box sx={{ width: "100%" }}>{secondaryTitle}</Box>
        </Box>
        {(iconPlacement === "right" || iconPlacement === "both") && (
          <IconButton
            color="inherit"
            onClick={() => {
              close(false, "icon2");
            }}
            sx={{ position: "absolute", right: 0 }}
          >
            {icon2}
          </IconButton>
        )}
      </Box>
    );
  };
  const handleOnClose = () => {
    if (onClose) close(false);
  };
  return (
    <Dialog
      open={open}
      fullScreen={device === "xs" || device === "sm" ? true : false}
      fullWidth
      TransitionComponent={Transition}
      onClose={handleOnClose}
      sx={{ zIndex: "1300" }}
    >
      {device === "xs" || device === "sm" ? (
        <AppBar sx={{ position: "relative" }} color="white">
          <Toolbar sx={{ width: "100%" }}>
            <Headers />
          </Toolbar>
        </AppBar>
      ) : (
        <DialogTitle id="scroll-dialog-title" sx={{ width: "100%", p: 1 }}>
          <Headers />
        </DialogTitle>
      )}
      <DialogContent sx={{ p: 0 }} dividers={true} className={classes.root}>
        {children}
      </DialogContent>
      {action && <DialogActions>{action}</DialogActions>}
    </Dialog>
  );
};
