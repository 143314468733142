import React, { useEffect } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Protected from "./components/atoms/ProtectedRoutes";
import Signup from "./components/pages/Signup";
import AlertMessage from "./components/atoms/AlertMessage";
import Login from "./components/pages/Login";
import Home from "./components/pages/Home";
import { useDispatch } from "react-redux";
import { checkDevice, updateUserAgent } from "./redux/slice/configuration";
import { detectOS } from "./utils/functions";
import Box from "@mui/material/Box";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "./utils/Theme";
import { FileUploadBuzzyPointer } from "./UI/FileUploadBusyPointer";
import { EmailVerification } from "./components/atoms/EmailVerification";
import ForgetPasswordEmail from "./components/forms/ForgetPasswordEmail";
import ResetPassword from "./components/forms/ResetPassword";
// import { onMessageListener } from "./firebase";
import { TermsAndCondition } from "./components/molecules/TermsAndCondition";
import { Privacy } from "./components/molecules/Privacy";
import { About } from "./components/molecules/About";

function App() {
  //-----------------------Declare Hooks Here-----------------------------//
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateUserAgent(detectOS()));
    window.addEventListener("resize", () =>
      dispatch(checkDevice(window.innerWidth))
    );
    // onMessageListener().then((response) => {
    //   new Notification(response.notification.title, {
    //     body: response.notification.body,
    //   });
    // });
    return window.removeEventListener("resize", () =>
      dispatch(checkDevice(window.innerWidth))
    );
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{}}>
        <AlertMessage />
        <FileUploadBuzzyPointer />
        <Routes>
          <Route
            exact
            path="/signup"
            element={
              <Protected auth={false}>
                <Signup />
              </Protected>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <Protected auth={false}>
                <Login />
              </Protected>
            }
          />
          <Route
            exact
            path="/forgetPassword"
            element={
              <Protected auth={false}>
                <ForgetPasswordEmail />
              </Protected>
            }
          />
          <Route
            exact
            path="/resetPassword/:urlParams"
            element={
              <Protected auth={false}>
                <ResetPassword />
              </Protected>
            }
          />
          <Route
            exact
            path="/confirmemail/:urlParams"
            element={
              <Protected auth={false}>
                <EmailVerification />
              </Protected>
            }
          />
          <Route
            exact
            path="/*"
            element={
              <Protected auth={true}>
                <Home />
              </Protected>
            }
          />
          <Route
            exact
            path="/terms"
            element={
              <Protected auth={false}>
                <TermsAndCondition />
              </Protected>
            }
          />
          <Route
            exact
            path="/about"
            element={
              <Protected auth={false}>
                <About />
              </Protected>
            }
          />
          <Route
            exact
            path="/privacy"
            element={
              <Protected auth={false}>
                <Privacy />
              </Protected>
            }
          />
        </Routes>
      </Box>
    </ThemeProvider>
  );
}

export default App;
