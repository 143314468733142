import Button from "@mui/material/Button";
import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveNodeLgId } from "../../redux/slice/familyTree";
import ShareIcon from "@mui/icons-material/Share";
export const FamilyTreeButton = ({ lg_id }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { relations } = useSelector((state) => state.userSummary);
  let isValid = relations.find((item) => item.userId === lg_id);
  const handleClick = () => {
    dispatch(
      updateActiveNodeLgId({ lg_id: isValid?.userId, name: isValid?.name })
    );
    navigate(`/explorer`);
  };
  return (
    <>
      {isValid ? (
        <Button startIcon={<ShareIcon />} variant="text" onClick={handleClick}>
          {t("display.text.button.explore")}
        </Button>
      ) : null}
    </>
  );
};
