import React from "react";
import { useParams } from "react-router-dom";
import { useGetLgSettingsQuery } from "../../redux/slice/lifografSettings";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { OwnerCard } from "../atoms/OwnerCard";
import { formatRelationship, getActiveUsers } from "../../utils/functions";
import { t } from "i18next";
import { AddRemoveCollabOwner } from "../atoms/AddRemoveCollabOwner";
import { LifografSettingPageSkleton } from "../Skleton/LifografSettingPageSkleton";
import { HelperIcon } from "../atoms/HelperIcon";
import LoadingButton from "@mui/lab/LoadingButton";

export const LifografSettingsPage = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const urlParams = useParams();
  const activeUsers = getActiveUsers();
  const { data, isLoading } = useGetLgSettingsQuery(urlParams.lg_id);

  //-----------------------Declare Function Here-----------------------------//

  const formatPossibleArray = (mainArr, secondaryArr) => {
    let sec = secondaryArr.map((item) => item.userId);
    let arr = mainArr.filter(({ userId }) => !sec.includes(userId));
    return [...arr];
  };
  return (
    <>
      {isLoading && <LifografSettingPageSkleton />}
      {data && !isLoading && (
        <Card sx={{ p: 1 }}>
          <Card sx={{ width: "100%", p: 1 }}>
            <Stack spacing={1}>
              <OwnerCard
                name={`${data?.owner?.fname} ${data?.owner?.lname}`}
                readURL={data?.owner?.readURL}
                lgScore={data?.owner?.lg_score}
                engagementScore={data?.owner?.engagement_score}
                relation={formatRelationship(data?.owner?.lg_id)}
                ownerType={data?.owner?.owner_type}
                status={
                  data?.owner?.self_usr === "Y"
                    ? t("display.text.text.active")
                    : t("display.text.text.inactive")
                }
              />
              <Divider></Divider>
              <Stack spacing={1}>
                {/* <Typography variant="h6">
                  {t("display.text.text.basicInfo")}
                </Typography>
                <Stack spacing={0.5}>
                  <Typography variant="body2" sx={{ color: "grey.text" }}>
                    {t("display.text.text.fname", {
                      param1: data?.owner?.fname,
                    })}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.text" }}>
                    {t("display.text.text.lname", {
                      param1: data?.owner?.lname,
                    })}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.text" }}>
                    {t("display.text.text.gender", {
                      param1: data?.owner?.gender,
                    })}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.text" }}>
                    {t("display.text.text.lg_url", {
                      param1: data?.owner?.lg_url,
                    })}
                  </Typography>
                </Stack>
                <Divider></Divider> */}
                {/* <Typography variant="h6">
                  {t("display.text.text.planDetails")}
                </Typography>
                <Stack spacing={0.5}>
                  {eventTypes.map((item, idx) => {
                    let event = data?.owner?.[`${item}Entries`];
                    return (
                      <Card
                        key={idx}
                        sx={{ backgroundColor: "background.dark", p: 1 }}
                      >
                        <Typography variant="body2">
                          {t(
                            `display.text.button.${findEventType(item).title}`
                          )}
                        </Typography>
                        <ul style={{ margin: "auto" }}>
                          <li>
                            <Typography
                              variant="body2"
                              sx={{ color: "grey.text" }}
                            >
                              {t("display.text.text.existing", {
                                param1: event?.cnt,
                              })}
                            </Typography>
                          </li>
                          {event?.maxCnt && (
                            <li>
                              <Typography
                                variant="body2"
                                sx={{ color: "grey.text" }}
                              >
                                {t("display.text.text.allowed", {
                                  param1: event?.maxCnt,
                                })}
                              </Typography>
                            </li>
                          )}
                          {event?.maxCnt && event?.cnt && (
                            <li>
                              <Typography
                                variant="body2"
                                sx={{ color: "grey.text" }}
                              >
                                {t("display.text.text.remaining", {
                                  param1:
                                    Number(event?.maxCnt) - Number(event?.cnt),
                                })}
                              </Typography>
                            </li>
                          )}
                        </ul>
                      </Card>
                    );
                  })}
                </Stack>
                <Divider></Divider> */}
                <Typography>
                  <Stack spacing={1} direction={"row"}>
                    <Box>{t("display.text.text.owners")}</Box>
                    <HelperIcon
                      helperText={
                        <Typography
                          sx={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {t("display.text.helperText.owner")}
                        </Typography>
                      }
                    />
                  </Stack>
                </Typography>
                <AddRemoveCollabOwner
                  lg_id={urlParams.lg_id}
                  value={data?.lgSettingList.filter(
                    (item) => item.access_status === "Owner"
                  )}
                  type="owners"
                  possibleValue={data?.lgSettingList.filter(
                    (item) => item.access_status === "Collab"
                  )}
                />
                <Divider></Divider>
                <Typography>
                  <Stack spacing={1} direction={"row"}>
                    <Box>{t("display.text.text.colloborators")}</Box>
                    <HelperIcon
                      helperText={
                        <Typography
                          sx={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {t("display.text.helperText.collab")}
                        </Typography>
                      }
                    />
                  </Stack>{" "}
                </Typography>{" "}
                <AddRemoveCollabOwner
                  lg_id={urlParams.lg_id}
                  value={data?.lgSettingList.filter(
                    (item) => item.access_status === "Collab"
                  )}
                  type="colloborators"
                  possibleValue={formatPossibleArray(
                    activeUsers,
                    data?.lgSettingList
                  )}
                />
                <Divider></Divider>
                <Typography>
                  <Stack spacing={1} direction={"row"}>
                    <Box>{t("display.text.text.blocked")}</Box>
                    <HelperIcon
                      helperText={
                        <Typography
                          sx={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {t("display.text.helperText.blocked")}
                        </Typography>
                      }
                    />
                  </Stack>{" "}
                </Typography>{" "}
                <AddRemoveCollabOwner
                  lg_id={urlParams.lg_id}
                  value={data?.lgSettingList.filter(
                    (item) => item.access_status === "blocked"
                  )}
                  type="blocked"
                  possibleValue={formatPossibleArray(
                    activeUsers,
                    data?.lgSettingList
                  )}
                />
              </Stack>
              <LoadingButton
                variant="contained"
                color="error"
                sx={{ width: "100%" }}
              >
                delete lifograf
              </LoadingButton>
            </Stack>
          </Card>
        </Card>
      )}
    </>
  );
};
