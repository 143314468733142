import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import { useStyles } from "../utils/Theme";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CustomDialogSwipeUp = ({
  children,
  title = "",
  secondaryTitle = "",
  open,
  close = () => {},
  iconPlacement = "left",
  icon = <CloseIcon />,
  icon2 = <CloseIcon />,
  onClose = true,
  fullWidth = false,
  color = "#fff",
  fontColor = "#000",
  sx = {},
}) => {
  //-----------------------Declare Hooks Here-----------------------------//
  const classes = useStyles();
  const { device } = useSelector((state) => state.config);
  useEffect(() => {
    if (open) {
      window.history.pushState(null, "", window.location.href);
      window.addEventListener("popstate", () => {
        close(false);
      });
    }
    // eslint-disable-next-line
  }, [open]);
  //-----------------------Declare Functions Here-----------------------------//

  const Headers = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          position: "relative",
          color: fontColor,
          borderRadius: 1,
        }}
      >
        {(iconPlacement === "left" || iconPlacement === "both") && (
          <IconButton
            color="inherit"
            onClick={() => {
              close(false);
            }}
            sx={{ position: "absolute" }}
          >
            {icon}
          </IconButton>
        )}
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              color: fontColor,
              lineHeight: "23.44px",
            }}
            align="center"
          >
            {title}
          </Typography>
          <Typography variant="caption" component={"div"} align="center">
            {secondaryTitle}
          </Typography>
        </Box>
        {(iconPlacement === "right" || iconPlacement === "both") && (
          <IconButton
            color="inherit"
            onClick={() => {
              close(false);
            }}
            sx={{ position: "absolute", right: 0 }}
          >
            {icon2}
          </IconButton>
        )}
      </Box>
    );
  };
  const handleOnClose = () => {
    if (onClose) close(false);
  };
  return (
    <>
      {device === "xs" || device === "sm" ? (
        <Drawer
          sx={{
            width: "100%",
            zIndex: "1500",
            ".MuiPaper-root": { borderRadius: 1 },
          }}
          anchor={"bottom"}
          open={open}
          onClose={handleOnClose}
        >
          <DialogTitle
            id="scroll-dialog-title"
            sx={{ width: "100%", p: 1, backgroundColor: color }}
          >
            <Headers />
          </DialogTitle>
          <DialogContent
            dividers={true}
            className={classes.root}
            sx={{ ...sx }}
          >
            {children}
          </DialogContent>
        </Drawer>
      ) : (
        <Dialog
          open={open}
          fullScreen={false}
          fullWidth={fullWidth}
          TransitionComponent={Transition}
          onClose={handleOnClose}
          sx={{ ...sx }}
        >
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              width: "100%",
              p: 1,
              backgroundColor: color,
            }}
          >
            <Headers />
          </DialogTitle>
          <DialogContent
            sx={{ ...sx }}
            dividers={true}
            className={classes.root}
          >
            {children}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
