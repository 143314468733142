import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lg_id: "",
  name: "",
};
export const familyTree = createSlice({
  name: "familyTree",
  initialState,
  reducers: {
    updateActiveNodeLgId: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetfamilyTree: () => {
      return initialState;
    },
  },
});

export const { updateActiveNodeLgId, resetfamilyTree } = familyTree.actions;
export default familyTree.reducer;
